<template>
    <v-container>
        <v-row v-if="get_selected_company.company_name">
            <v-col cols="12" class="mt-15 text-center">
                <h1 class="display-2 font-weight-bold mb-3">
                    Welcome to {{ get_selected_company.company_name }}
                </h1>
            </v-col>
            <v-col cols="2">
                <h2>Reports</h2>
                <v-btn @click="openBookingReportDialog()" color="primary" class="mt-5">Bookings</v-btn>
                <br>
                <v-btn @click="openItineraryReportDialog()" color="primary" class="mt-5">Itineraries</v-btn>
            </v-col>
            <v-col cols="2">
                <h2>Emails</h2>
                <v-btn @click="openEmailDialog()" color="primary" class="mt-5">Send Bulk Emails</v-btn>
            </v-col>
        </v-row>
        <v-dialog :value="booking_report_dialog" persistent>
            <v-card>
                <v-card-title>
                    Booking Reports
                    <v-spacer></v-spacer>
                    <v-btn @click="closeBookingReportDialog()" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2" style="display:flex;align-items:center;justify-content:end;">
                            Start/Tour Date
                        </v-col>
                        <v-col cols="3" class="px-3">
                            <v-menu v-model="booking_start_from_menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="booking_start_from_date" label="From" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="booking_start_from_date"
                                    @input="booking_start_from_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3" class="px-3">
                            <v-menu v-model="booking_start_to_menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="booking_start_to_date" label="To" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="booking_start_to_date"
                                    @input="booking_start_to_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-select multiple v-model="selected_status" label="Filter by status" class="mx-5 mt-5"
                                dense :items="[{ name: 'None', value: null }, ...get_statuses]" item-text="name"
                                item-value="id"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-btn @click="getBookingReport()" dark>Search</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="!loading">
                            {{ itineraries.length }} itineraries and {{ accounting_report_data.length }} bookings found
                        </v-col>
                        <v-col cols="12" v-if="itineraries.length > 0 && !loading">
                            <download-csv :data="accounting_report_data">
                                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2"
                                    style="text-transform: unset !important;">
                                    <v-icon class="mr-2">
                                        mdi-download
                                    </v-icon>
                                    Export for Accounting
                                </v-btn>
                            </download-csv>
                        </v-col>
                        <v-col cols="6" v-if="itineraries.length > 0 && !loading">
                            <download-csv :data="supplier_purchases_report_data">
                                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2"
                                    style="text-transform: unset !important;">
                                    <v-icon class="mr-2">
                                        mdi-download
                                    </v-icon>
                                    Export Supplier Purchases
                                </v-btn>
                            </download-csv>
                        </v-col>
                        <v-col cols="6" v-if="itineraries.length > 0 && !loading">
                            <v-select v-model="selected_supplier" @change="filterBySupplier()"
                                label="Filter supplier purchases" class="mx-5 mt-5" dense :items="suppliers"
                                item-text="supplier_name" item-value="id" return-object></v-select>
                        </v-col>
                        <v-col cols="12" v-if="loading">
                            Searching...
                        </v-col>
                        <v-col cols="12" v-if="accounting_report_data.length > 0 && !loading">
                            <v-data-table :items="accounting_report_data" :headers="booking_table_headers"
                                disable-pagination hide-default-footer>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.ContactName }}</td>
                                        <td>{{ item.EmailAddress }}</td>
                                        <td>{{ item.InvoiceNumber }}</td>
                                        <td>{{ item.InvoiceDate }}</td>
                                        <td>{{ item.DueDate }}</td>
                                        <td>{{ item.SubTotal }}</td>
                                        <td>{{ item.TotalTax }}</td>
                                        <td>{{ item.Total }}</td>
                                        <td>{{ item.Description }}</td>
                                        <td>{{ item.Quantity }}</td>
                                        <td>{{ item.UnitAmount }}</td>
                                        <td>{{ item.AccountCode }}</td>
                                        <td>{{ item.TaxType }}</td>
                                        <td>{{ item.TaxAmount }}</td>
                                        <td>{{ item.TrackingName1 }}</td>
                                        <td>{{ item.TrackingOption1 }}</td>
                                        <td>{{ item.TrackingName2 }}</td>
                                        <td>{{ item.TrackingOption2 }}</td>
                                        <td>{{ item.Currency }}</td>
                                        <td>{{ item.POAddressLine1 }}</td>
                                        <td>{{ item.POAddressLine2 }}</td>
                                        <td>{{ item.POAddressLine3 }}</td>
                                        <td>{{ item.POAddressLine4 }}</td>
                                        <td>{{ item.POCity }}</td>
                                        <td>{{ item.PORegion }}</td>
                                        <td>{{ item.POPostalCode }}</td>
                                        <td>{{ item.POCountry }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog :value="itinerary_report_dialog" persistent>
            <v-card>
                <v-card-title>
                    Itinerary Reports
                    <v-spacer></v-spacer>
                    <v-btn @click="closeItineraryReportDialog()" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2" style="display:flex;align-items:center;justify-content:end;">
                            Start/Tour Date
                        </v-col>
                        <v-col cols="3" class="px-3">
                            <v-menu v-model="itinerary_start_from_menu" :close-on-content-click="false"
                                :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="itinerary_start_from_date" label="From" readonly
                                        v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="itinerary_start_from_date"
                                    @input="itinerary_start_from_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3" class="px-3">
                            <v-menu v-model="itinerary_start_to_menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="itinerary_start_to_date" label="To" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="itinerary_start_to_date"
                                    @input="itinerary_start_to_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-select multiple v-model="selected_status" label="Status" class="mx-5 mt-5" dense
                                :items="[{ name: 'None', value: null }, ...get_statuses]" item-text="name"
                                item-value="id"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-btn @click="getItineraryReport()" dark>Search</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="!loading">
                            {{ itineraries.length }} itineraries found
                        </v-col>
                        <v-col cols="12" v-if="itineraries.length > 0 && !loading">
                            <download-csv :data="itinerary_report_data">
                                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2"
                                    style="text-transform: unset !important;">
                                    <v-icon class="mr-2">
                                        mdi-download
                                    </v-icon>
                                    Export CSV
                                </v-btn>
                            </download-csv>
                        </v-col>
                        <v-col cols="12" v-if="loading">
                            Searching...
                        </v-col>
                        <v-col cols="12" v-if="itinerary_report_data.length > 0 && !loading">
                            <v-data-table :items="itinerary_report_data" :headers="itinerary_table_headers"
                                disable-pagination hide-default-footer>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.ArrivalDate }}</td>
                                        <td>{{ item.NumberOfNights }}</td>
                                        <td>{{ item.BookingDate }}</td>
                                        <td>{{ item.ItineraryId }}</td>
                                        <td>{{ item.ItineraryName }}</td>
                                        <td>{{ item.Passengers }}</td>
                                        <td>{{ item.MainEmail }}</td>
                                        <td>{{ item.TourType }}</td>
                                        <td>{{ item.AssignedSales }}</td>
                                        <td>{{ item.AssignedBookings }}</td>
                                        <td>{{ item.AssignedVA }}</td>
                                        <td>{{ item.AssignedAccounts }}</td>
                                        <td>{{ item.CompanyName }}</td>
                                        <td>{{ item.Countries }}</td>
                                        <td>{{ item.TravelStatus }}</td>
                                        <td>{{ item.PaymentPercent }}</td>
                                        <td>{{ item.ResActionReq }}</td>
                                        <td>{{ item.SellPrice }}</td>
                                        <td>{{ item.CostOfSales }}</td>
                                        <td>{{ item.Margin }}</td>
                                        <td>{{ item.CustomerPayment }}</td>
                                        <td>{{ item.AmountDueFromClient }}</td>
                                        <td>{{ item.Surcharge }}</td>
                                        <td>{{ item.Status }}</td>
                                        <td>{{ item.CustomerInvoicing }}</td>
                                        <td>{{ item.FinalPaymentDueDate }}</td>
                                        <td>{{ item.Stage }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog :value="email_dialog" persistent max-width="800">
            <v-card>
                <v-card-title>
                    Send Emails
                    <v-spacer></v-spacer>
                    <v-btn @click="closeEmailDialog()" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="from_email" label="Sender email" outlined></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="email_subject" label="Subject" outlined></v-text-field>
                        </v-col>
                        <v-col>
                            <v-file-input v-model="recipients_csv" label="Reipients CSV" outlined></v-file-input>
                        </v-col>
                    </v-row>
                    <wysiwyg v-model="email_body" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeEmailDialog()" text>Close</v-btn>
                    <v-btn @click="sendEmails()" dark>Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Dashboard',
    data: () => ({
        booking_report_dialog: false,
        itinerary_report_dialog: false,
        booking_start_from_menu: false,
        booking_start_to_menu: false,
        booking_start_from_date: null,
        booking_start_to_date: null,
        itinerary_start_from_menu: false,
        itinerary_start_to_menu: false,
        itinerary_start_from_date: null,
        itinerary_start_to_date: null,
        itineraries: [],
        selected_status: [],
        loading: false,
        accounting_report_data: [],
        supplier_purchases_report_data: [],
        suppliers: [],
        original_data: [],
        itinerary_report_data: [],
        email_dialog: false,
        email_body: null,
        from_email: null,
        email_subject: null,
        recipients_csv: null,
        selected_supplier: null,
        booking_table_headers: [
            { text: 'ContactName', sortable: false },
            { text: 'EmailAddress', sortable: false },
            { text: 'InvoiceNumber', sortable: false },
            { text: 'InvoiceDate', sortable: false },
            { text: 'DueDate', sortable: false },
            { text: 'SubTotal', sortable: false },
            { text: 'TotalTax', sortable: false },
            { text: 'Total', sortable: false },
            { text: 'Description', sortable: false },
            { text: 'Quantity', sortable: false },
            { text: 'UnitAmount', sortable: false },
            { text: 'AccountCode', sortable: false },
            { text: 'TaxType', sortable: false },
            { text: 'TaxAmount', sortable: false },
            { text: 'TrackingName1', sortable: false },
            { text: 'TrackingOption1', sortable: false },
            { text: 'TrackingName2', sortable: false },
            { text: 'TrackingOption2', sortable: false },
            { text: 'Currency', sortable: false },
            { text: 'POAddressLine1', sortable: false },
            { text: 'POAddressLine2', sortable: false },
            { text: 'POAddressLine3', sortable: false },
            { text: 'POAddressLine4', sortable: false },
            { text: 'POCity', sortable: false },
            { text: 'PORegion', sortable: false },
            { text: 'POPostalCode', sortable: false },
            { text: 'POCountry', sortable: false },
        ],
        itinerary_table_headers: [
            { text: 'ArrivalDate', sortable: false },
            { text: 'NumberOfNights', sortable: false },
            { text: 'BookingDate', sortable: false },
            { text: 'ItineraryId', sortable: false },
            { text: 'ItineraryName', sortable: false },
            { text: 'Passengers', sortable: false },
            { text: 'MainEmail', sortable: false },
            { text: 'TourType', sortable: false },
            { text: 'AssignedSales', sortable: false },
            { text: 'AssignedBookings', sortable: false },
            { text: 'AssignedVA', sortable: false },
            { text: 'AssignedAccounts', sortable: false },
            { text: 'CompanyName', sortable: false },
            { text: 'Countries', sortable: false },
            { text: 'TravelStatus', sortable: false },
            { text: 'PaymentPercent', sortable: false },
            { text: 'ResActionReq', sortable: false },
            { text: 'SellPrice', sortable: false },
            { text: 'CostOfSales', sortable: false },
            { text: 'Margin', sortable: false },
            { text: 'CustomerPayment', sortable: false },
            { text: 'AmountDueFromClient', sortable: false },
            { text: 'Surcharge', sortable: false },
            { text: 'Status', sortable: false },
            { text: 'CustomerInvoicing', sortable: false },
            { text: 'FinalPaymentDueDate', sortable: false },
            { text: 'Stage', sortable: false },
        ],
    }),
    async mounted() {
        this.getAllStatuses()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_statuses: 'itineraryV2/get_statuses',
        }),
    },
    methods: {
        async getAllStatuses() {
            await this.$axios.get('v2/itineraries/get_statuses')
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('itineraryV2/set_companies', data.data.companies)
                        this.$store.dispatch('itineraryV2/set_statuses', data.data.statuses)
                        this.$store.dispatch('itineraryV2/set_templates', data.data.templates)
                        this.$store.dispatch('itineraryV2/set_tour_types', data.data.tour_types)
                        this.$store.dispatch('itineraryV2/set_invoice_statuses', data.data.invoice_statuses)
                        this.$store.dispatch('itineraryV2/set_stages', data.data.stages)
                        this.$store.dispatch('itineraryV2/set_supplier_types', data.data.supplier_types)
                        this.$store.dispatch('itineraryV2/set_product_types', data.data.product_types)
                        this.$store.dispatch('itineraryV2/set_booking_statuses', data.data.booking_statuses)
                        this.$store.dispatch('itineraryV2/set_booking_payment_statuses', data.data.booking_payment_statuses)
                        this.$store.dispatch('itineraryV2/set_currencies', data.data.currencies)
                        this.$store.dispatch('itineraryV2/set_user_roles', data.data.user_roles)
                        this.$store.dispatch('itineraryV2/set_travel_statuses', data.data.travel_statuses)
                    }
                })
        },
        openBookingReportDialog() {
            this.booking_report_dialog = true
            this.selected_status = [1]
        },
        closeBookingReportDialog() {
            this.booking_report_dialog = false
            this.itineraries = []
        },
        getBookingReport() {
            this.loading = true
            let payload = {
                itinerary_status_id: this.selected_status,
                start_from_date: (this.booking_start_from_date != null && this.booking_start_from_date != 'None') ? this.booking_start_from_date : null,
                start_to_date: (this.booking_start_to_date != null && this.booking_start_to_date != 'None') ? this.booking_start_to_date : null,
            }
            this.$axios.get(`v2/itineraries/get_itineraries_and_bookings_by_filter/${this.get_selected_company.id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.itineraries = data.data
                        this.loading = false
                        this.setAccountingReport()
                        this.setSupplierPurchasesReport()
                    }
                })
        },
        setAccountingReport() {
            this.accounting_report_data = []
            this.itineraries.forEach(itinerary => {
                itinerary.days.forEach(day => {
                    day.bookings.forEach(booking => {
                        if (booking.option) {
                            let days = (itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                                ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                                : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                            if (days == 0) {
                                days = 1
                            }
                            let new_data = {
                                ContactName: booking.option.product.supplier.supplier_name,
                                EmailAddress: booking.option.product.supplier.email,
                                POAddressLine1: null,
                                POAddressLine2: null,
                                POAddressLine3: null,
                                POAddressLine4: null,
                                POCity: null,
                                PORegion: null,
                                POPostalCode: null,
                                POCountry: null,
                                InvoiceNumber: itinerary.itinerary_name + ' - ' + itinerary.itinerary_number + ' - ' + booking.id,
                                InvoiceDate: this.$date(itinerary.start_date).format('DD/MM/YYYY'),
                                DueDate: this.$date(itinerary.end_date).format('DD/MM/YYYY'),
                                SubTotal: null,
                                TotalTax: null,
                                Total: null,
                                Description: (booking.option.product ? booking.option.product.name : null) + ' - ' + booking.option.name,
                                Quantity: booking.count,
                                UnitAmount: booking.rate_day ? (booking.rate_day.net * booking.count * days) : 0,
                                AccountCode: '620 - Prepayments & Accrued Income',
                                TaxType: this.get_selected_company.id == 6 ? 'Tax Excempt (0%)' : 'Tax on Purchases (23%)',
                                TaxAmount: null,
                                TrackingName1: null,
                                TrackingOption1: null,
                                TrackingName2: null,
                                TrackingOption2: null,
                                Currency: booking.option.product.supplier.currency ? booking.option.product.supplier.currency.code : ''
                            }
                            this.accounting_report_data.push(new_data)
                        }
                    })
                })
            })
        },
        setSupplierPurchasesReport() {
            this.supplier_purchases_report_data = []
            this.original_data = []
            this.suppliers = []
            this.itineraries.forEach(itinerary => {
                itinerary.days.forEach(day => {
                    day.bookings.forEach(booking => {
                        if (booking.option.product.supplier && booking.option) {
                            let days = (itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                                ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                                : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')
                            if (days == 0) {
                                days = 1
                            }
                            let currency = booking.option.product.supplier.currency ? booking.option.product.supplier.currency.code : ''
                            let new_data = {
                                Supplier: booking.option.product.supplier.supplier_name + ' (' + currency + ')',
                                Date: this.$date(booking.check_in_date).format('DD/MM/YYYY'),
                                Itinerary: itinerary.itinerary_name,
                                Service_Option: (booking.option.product ? booking.option.product.name : null) + ', ' + booking.option.name,
                                Booking_ID: booking.id,
                                Booking_Ref: booking.booking_reference_number,
                                Itin_Status: itinerary.status.name,
                                Bkg_Status: booking.booking_status ? booking.booking_status.status : 'Not yet requested',
                                Gross: currency + ' ' + (booking.rate_day ? (booking.rate_day.gross * booking.count * days) : 0),
                                Net: currency + ' ' + (booking.rate_day ? (booking.rate_day.net * booking.count * days) : 0),
                                Days: days,
                                Quantity: booking.count,
                                Supplier_ID: booking.option.product.supplier.id,
                                Product_ID: booking.option.product.id,
                                Option_ID: booking.option.id,
                            }
                            this.supplier_purchases_report_data.push(new_data)
                            this.original_data.push(new_data)
                            this.suppliers.push(booking.option.product.supplier)
                        }
                    })
                })
            })
            this.supplier_purchases_report_data = this.supplier_purchases_report_data.sort(function (a, b) {
                if (a.Supplier < b.Supplier) {
                    return -1;
                }
                if (a.Supplier > b.Supplier) {
                    return 1;
                }
                return 0;
            })

            console.log(this.supplier_purchases_report_data)
        },
        filterBySupplier() {
            this.supplier_purchases_report_data = this.original_data.filter(x => x.Supplier == (this.selected_supplier.supplier_name + ' (' + this.selected_supplier.currency.code + ')'))
        },
        openItineraryReportDialog() {
            this.itinerary_report_dialog = true
            this.selected_status = [4, 5, 6, 7]
        },
        closeItineraryReportDialog() {
            this.itinerary_report_dialog = false
            this.itineraries = []
        },
        getItineraryReport() {
            this.loading = true
            let payload = {
                itinerary_status_id: this.selected_status,
                start_from_date: (this.itinerary_start_from_date != null && this.itinerary_start_from_date != 'None') ? this.itinerary_start_from_date : null,
                start_to_date: (this.itinerary_start_to_date != null && this.itinerary_start_to_date != 'None') ? this.itinerary_start_to_date : null,
            }
            this.$axios.get(`v2/itineraries/get_itineraries_by_filter/${this.get_selected_company.id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.itineraries = data.data
                        this.loading = false
                        this.setItineraryReport()
                    }
                })
        },
        setItineraryReport() {
            this.itinerary_report_data = []
            this.itineraries.forEach(itinerary => {
                let paid_amount = 0
                if (itinerary.payments != null && itinerary.payments.length > 0) {
                    paid_amount = itinerary.payments.reduce((sum, payment) => sum + payment.amount, 0)
                }
                let countries = ''
                if (itinerary.countries != null) {
                    itinerary.countries.forEach((country, index) => {
                        countries += country
                        if (index < itinerary.countries.length - 1) {
                            countries += ', '
                        }
                    })
                }
                let new_data = {
                    ArrivalDate: this.$date(itinerary.start_date).format('DD-MMM-YYYY'),
                    NumberOfNights: this.$date(itinerary.end_date).diff(this.$date(itinerary.start_date), 'DAY'),
                    BookingDate: this.$date(itinerary.created_at).format('DD-MMM-YYYY'),
                    ItineraryId: itinerary.id,
                    ItineraryName: itinerary.itinerary_name,
                    Passengers: itinerary.travellers_number,
                    MainEmail: (itinerary.travellers && itinerary.travellers.length > 0) ? itinerary.travellers[0].email : '',
                    TourType: itinerary.tour_type ? itinerary.tour_type.name : '',
                    AssignedSales: itinerary.contributors.length > 0 && itinerary.contributors.filter(x => x.user.user_role_id == 2).length > 0 ? itinerary.contributors.filter(x => x.user.user_role_id == 2)[0].user.name : null,
                    AssignedBookings: itinerary.contributors.length > 0 && itinerary.contributors.filter(x => x.user.user_role_id == 3).length > 0 ? itinerary.contributors.filter(x => x.user.user_role_id == 3)[0].user.name : null,
                    AssignedVA: itinerary.contributors.length > 0 && itinerary.contributors.filter(x => x.user.user_role_id == 4).length > 0 ? itinerary.contributors.filter(x => x.user.user_role_id == 4)[0].user.name : null,
                    AssignedAccounts: itinerary.contributors.length > 0 && itinerary.contributors.filter(x => x.user.user_role_id == 5).length > 0 ? itinerary.contributors.filter(x => x.user.user_role_id == 5)[0].user.name : null,
                    CompanyName: itinerary.company ? itinerary.company.company_name : '',
                    Countries: countries,
                    TravelStatus: itinerary.travel_status ? itinerary.travel_status.name : '',
                    PaymentPercent: ((paid_amount / itinerary.gross) * 100).toFixed(0) + '%', // Need to account for total gross after conversion into USD
                    ResActionReq: itinerary.res_action_required,
                    SellPrice: itinerary.gross,
                    CostOfSales: itinerary.net,
                    Margin: itinerary.margin,
                    CustomerPayment: paid_amount.toFixed(2),
                    AmountDueFromClient: (itinerary.gross - paid_amount).toFixed(2),
                    Surcharge: (paid_amount - itinerary.gross).toFixed(2),
                    Status: itinerary.status ? itinerary.status.name : '',
                    CustomerInvoicing: itinerary.invoice_status ? itinerary.invoice_status.name : '',
                    FinalPaymentDueDate: this.$date(itinerary.start_date).subtract(42, 'day').format('DD-MMM-YYYY'),
                    Stage: itinerary.stage ? itinerary.stage.name : '',
                }
                this.itinerary_report_data.push(new_data)
            })
        },
        openEmailDialog() {
            this.email_dialog = true
        },
        closeEmailDialog() {
            this.email_dialog = false
            this.recipients_csv = null
            this.from_email = null
            this.email_body = null
            this.email_subject = null
        },
        async sendEmails() {
            if (this.recipients_csv == null || this.from_email == null || this.email_body == null || this.email_subject == null) {
                this.$toast('Please fill out all forms')
                return
            }
            const formData = new FormData()
            formData.append('file', this.recipients_csv)
            formData.append('from_email', this.from_email)
            formData.append('email_subject', this.email_subject)
            formData.append('email_body', this.email_body)
            await this.$axios.post('dashboard/send_emails', formData)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast(data.message)
                    }
                })
        },
    }
}
</script>